<template>
    <div>
        <rxNavBar v-if="androidFlg == false" title="工资审批列表" androidOrIOSFlag="true"></rxNavBar>
        <rxNavBar v-else title="工资审批列表"></rxNavBar>
        <div class="head">
            <div class="search">
                <div class="search-icon" @click="goSearch"></div>
                <input type="text" placeholder="工资包id" v-model="searchContent" @keyup.enter="goSearch">
            </div>
            <div class="types">
                <div class="type" @click="isShow = !isShow" id="downList">
                    {{ typeList[number] ? typeList[number].dictionaryTitle : '未审批' }}
                </div>
                <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
            </div>
        </div>
        <van-popup v-model="isShow" position="bottom">
            <van-picker show-toolbar :columns="typeList" @cancel="isShow = false" :default-index="number" value-key="dictionaryTitle" @confirm="changeType"/>
        </van-popup>
        <div class="auditDiv">
            <van-pull-refresh v-model="refreshing"  @refresh="onRefresh"   >
                <van-list v-model="loading" :finished="finished" finished-text="" @load="this.onLoad">       
                    <div class="stateList" v-for="(item,index) in orders" :key="index">
                        <div>
                            <!--                循环列表状态 -->
                            <div v-if="item.salaryStatus==0" class="stateUnapproval">
                                {{ item.salaryStatusName }}
                            </div>
                            <div v-if="item.salaryStatus==4" class="stateApproving">
                                {{ item.salaryStatusName }}
                            </div>
                            <div v-if="item.salaryStatus==1" class="statePassed">
                                {{ item.salaryStatusName }}
                            </div>
                            <div v-if="item.salaryStatus==2 || item.salaryStatus==3" class="stateRejected">
                                {{ item.salaryStatusName }}
                            </div>
                            <div v-if="item.salaryStatus==5" class="stateGranted">
                                {{ item.salaryStatusName }}
                            </div>
                            <!--                循环列表头-->

                            <!--                循环列表详细信息-->
                            <div class="listDetail">
                                    <div class="proposerDiv">公司</div>
                                    <!--                    第一条竖线-->
                                    <div class="rule1"></div>
                                    <div  class="budgetDiv">年月</div>
                                    <!--                    第二条竖线-->
                                    <div class="rule2"></div>
                                    <div class="remouldDiv">应发人数</div>
                                    <!--                    获取申请人的信息-->
                                    <div class="proposerValueDiv">{{ item.company_name  }}</div>
                                    <!--                    获取装修费用值的信息-->
                                    <div class="budgetValueDiv">{{ item.salaryTime }}</div>
                                    <!--                    获取改造房屋的信息-->
                                    <div class="remouldValueDiv">{{ item. countList.countNum }}人</div>
                            </div>
                            <div class="rule3"></div>
                            <!--                循环列表下部分-->
                            <div class="listFloor">
                                    <div class="createMethodsDiv">工资包id : </div>
                                    <!-- <div v-if="item.salaryStatus==1" class="createMethodsDiv">通过时间 :</div>
                                    <div v-if="item.salaryStatus==2" class="createMethodsDiv">驳回时间 :</div> -->
                                    <div class="createTimeDiv">{{ item.id }}</div>
                                    <!--审核按钮-->
                                    <div>
                                        <!--圈经审批v-if="item.authority == 1"-->
                                        <button class="btn1" v-if="item.authority == 1"  @click="approve(item)">审批</button>
                                        <!-- <button class="btn1" v-if="item.salaryStatus == 0 &&checkAuthList(authButtonsList,'examineApprove')"  @click="approve(item)">审批</button> -->
                                        <!--查看按钮-->
                                        <button class="btn2" v-else @click="showDetail(item)">查看</button>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <!--空状态显示-->
                    <common-empty v-if="isEmptuyFlag"></common-empty>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    import {
        Button, Search, DropdownMenu, DropdownItem, Divider, List, NavBar, Picker,PullRefresh,Popup,
    } from 'vant';
    import {queryPayrollApprovalStatus, getStaffFuntionModelList, queryBaseData,queryPayrollmanageList} from "../../../getData/getData";
    import {checkAuthList, getStaffId, responseUtil,money,checkAndroid, checkIOS,} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
export default {
    name: 'salaryApproval',
    components: {
        [Button.name]: Button,
        [Search.name]: Search,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Divider.name]: Divider,
        [NavBar.name]: NavBar,
        [List.name]: List,
        rxNavBar,
        [Picker.name]: Picker,
        [Popup.name]: Popup,
        [PullRefresh.name]:PullRefresh,
    },
    data(){
        return{
            androidFlg: '',
            authButtonsList:[],    //权限
            typeList:[],
            number:4,
            searchContent:'', //搜索
            orders:[{
                salaryStatus:0,
                salaryStatusName:'待审批',
                company_name:'大连海米',
                salaryTime:'2023-04',
                staffAmount:181,
                countList:{
                    actualAmount:'0.00'
                }
            }],  //列表
            page:{currentPage:1,numberPage:3,},
            refreshing: false,
            loading : true,
            isShow: false,
            isEmptuyFlag:true,
            finished: false,
            flag:true
        }
    },
    beforeRouteEnter(to, from, next) {
        //清除本地localStorage缓存
        if(from.name == 'staffMore'){
            localStorage.removeItem('currentApproveLabel');
        }
        next()
    },
    beforeRouteLeave(to, from, next){
        // if(to.name == 'salaryApprovalDetail'){
        //     from.meta.keepAlive = true;
        // }else {
        //     from.meta.keepAlive = false;
        // }
        next()
    },
    mounted: function () {
      this.checkPhoneorMobel()
      this.orders=[]
        this.getStaffFuntionModelList()
        this.initChooseTypes()
        let currentLabel=4
        if(localStorage.getItem('currentApproveLabel')!=undefined){
           currentLabel = JSON.parse(localStorage.getItem('currentApproveLabel')); //先读取local里存储的历史记录
        }
        if (currentLabel != null) {
            if (currentLabel != '') {
                this.number = Number(currentLabel)
            }
        }
        this.finished = false;
        this.loading = true;
        this.refreshing = false
        this.onLoad();
    },
    methods:{
        checkPhoneorMobel() {
          if(checkAndroid()) {
            this.androidFlg = true
          }
          else if(checkIOS()) {
            this.androidFlg = false
          }
        },
        //下拉刷新
        onRefresh() {
        // 清空列表数据
            this.finished = false;
            this.orders = []
            this.page =  {currentPage:1,numberPage:3}
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.refreshing = false
            this.onLoad();
            responseUtil.alertMsg(this,"刷新成功")
        },
        //搜索框查询
        goSearch() {
            this.orders = []
            this.page = {currentPage:1,numberPage:3}
            if (this.searchContent == '') {
                location.reload()
            }
            this.onLoad()  //下拉加载
        },

        //下拉菜单初始化选择类型
        initChooseTypes: function () {
            let that = this
            let initData = {}
            queryPayrollApprovalStatus(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.typeList = []
                    for (let key in response.data.data) {
                        let obj = {};
                        obj.dictionaryValue = key
                        obj.dictionaryTitle = response.data.data[key]
                        that.typeList.push(obj);
                    }
                    that.typeList.push({dictionaryValue:'',dictionaryTitle:'全部审批'});
                })
            })
        },
         //更改选择类型
         changeType: function (value, index) {
            //将选择的类型放入本地内存中，
            if (index == '') {
                index = 0;
            }
            localStorage.setItem('currentApproveLabel', JSON.stringify(index))
            this.flag = true
            this.finished = true;
            this.orders = [];
            this.page = {currentPage:1,numberPage:3};
            this.loading = false;
            this.finished = false;
            this.isShow = false;
            this.number = index;
            this.loading = true;
            this.onLoad();
        },


        //加载
        onLoad(){
            let that=this
            let queryData={}
            // queryData.type='19'
            queryData.payrollmanage_id = this.searchContent
            queryData.currentPage=that.page.currentPage
            queryData.numberPage=that.page.numberPage
            queryData.user_id=getStaffId()
            queryData.salaryStatus=that.number <6?that.number:''
            queryPayrollmanageList(queryData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.count = response.data.data.rowCount
                    let list=response.data.data.data
                    for (let i = 0; i < list.length; i++) {
                        // list[i].countList.actualAmount = list[i].countList.actualAmount?money(list[i].countList.actualAmount):money(0)
                        that.orders.push(list[i])
                    }
                    if(that.refreshing){
                        that.refreshing = false
                    }
                    // 加载状态结束
                    that.loading = false;
                    that.page.currentPage++;
                    // 数据全部加载完成
                    if (that.orders.length >= that.count) {
                        that.finished = true;
                    }
                    //判断数据是否为空，空则显示空状态图
                    if (that.orders.length == 0) {
                        that.isEmptuyFlag = true;
                    } else {
                        that.isEmptuyFlag = false;
                    }                        
                })
            }) 
        },


        approve(item){
            this.$router.push({
                name: 'salaryApprovalDetail',
                query: {
                    type:'approval',
                    company_name:item.company_name,  //公司名称
                    countList:item.countList,   //统计数据
                    payrollmanage_id:item.id,  //工资包id
                    salaryhistory_id:item.salaryhistory_id,  //工资包id
                    salaryTime: item.salaryTime,  //工资月份
                    staffAmount:item.staffAmount,  //总人数
                    addStaffName:item.addStaffName,  //添加人
                    salaryStatus:item.salaryStatus,  //状态
                    salaryStatusName:item.salaryStatusName

                }
            })
        },

        showDetail(item){
            this.$router.push({
                name: 'salaryApprovalDetail',
                query:{
                    type:'detail',
                    company_name:item.company_name,  //公司名称
                    countList:item.countList,   //统计数据
                    payrollmanage_id:item.id,  //工资包id
                    salaryTime: item.salaryTime,  //工资月份
                    staffAmount:item.staffAmount,  //总人数
                    addStaffName:item.addStaffName,  //添加人
                    salaryStatus:item.salaryStatus,  //状态
                    salaryStatusName:item.salaryStatusName
                                    
                }
            });     
        },





        checkAuthList,
        getStaffFuntionModelList(){
            var that = this
            let data = {}
            data.staff_id = getStaffId()
            data.menuName = 'salaryApplication_index'
            getStaffFuntionModelList(data).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                that.authButtonsList = response.data.data.data
                })
            })
        },
    }
}
</script>

<style lang="less" scoped>
.nav_bar_title {
  z-index: 99;
}

.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
}

.search {
  margin: 8px 20px 0 20px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(244, 244, 244, 1);
  display: flex;
  align-items: center;
}

.search-icon {
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

input {
  font-size: 14px;
  margin-left: 10px;
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 250px;
}

input::-webkit-input-placeholder {
  color: rgba(199, 199, 199, 1);
}

.types {
  width: 100%;
  height: 47px;
  display: flex;
}

.type {
  width: auto;
  height: 17px;
  line-height: 17px;
  text-align: left;
  color: #fe5e3a;
  margin: 15px 0 0 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.small-icon {
  width: 8px;
  height: 8px;
  margin: 19px 0 0 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin: 18px 0 0 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

.choose-select {
  position: fixed;
  z-index: 6;
  padding-top: 90px;
  width: 100%;
}

.selectList {
  width: 100%;
  height: auto;
  padding: 5px 0 14px 0;
  background-color: white;
}

.select {
  width: 100%;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  text-align: left;
  margin-left: 15px;
  color: #999999;
}

.selectActive {
  color: #fe5e3a;
}

.h-line {
  height: 1px;
  margin: 0 5px 0 10px;
  background-color: white;
}

/*    下拉菜单*/
.downMenuDiv {
  width: 2.2rem;
}

.auditDiv {
  padding-top: 91px;
}

/*    循环列表DIV*/
.stateList {
  border-radius: 0.3rem;
  width: 93%;
  margin-left: 0.35rem;
  position: relative;
  margin-bottom: 0.4rem;
  background-color: #ffffff;
  padding-bottom: 0.5rem;
}

.stateUnapproval, .statePassed, .stateRejected, .stateApproving, .stateGranted {
  height: 0.45rem;
  width: fit-content;
  padding: 0 0.13rem;
  border-radius: 0.2rem 0rem;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  line-height: 0.5rem;
}

/*列表状态样式 1是未审批*/
.stateUnapproval {
  background: -webkit-linear-gradient(left,rgb(124, 198, 255),rgb(54, 147, 255));
//   background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
}

/*列表状态样式 2是已审批*/
.statePassed {
  background: -webkit-linear-gradient(left, #66CB94, #28D3B0);
}

/*列表状态样式 5是审批中*/
.stateApproving{
    background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
}

/*列表状态样式 3是已驳回 4已撤回*/
.stateRejected {
  background: -webkit-linear-gradient(left, #E02020, #FF431B);
}

/*列表状态样式 6是已发放*/
.stateGranted{
    background: -webkit-linear-gradient(left, #999999,#cccccc);
}

/*列表头部红色重点信息*/
.headSpan {
  float: left;
  margin-right: 0.1rem;
  color: #FF5D3B;
}

/*列表头部小圆点*/
.dot {
  border-radius: 50%;
  width: 0.01rem;
  height: 0.01rem;
  border: 0.05rem solid black;
  float: left;
  margin-top: 0.24rem;
  margin-right: 0.1rem;
}

/*列表头部信息*/
.headInfo {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  font-size: 15px;
  font-weight: bold;
}

/*列表详情*/
.listDetail {
  position: relative;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.5rem;
}

.listDetail:after {
  content: "";
  display: block;
  clear: both;
}

/*    申请人信息DIV*/
.proposerDiv {
  margin-left: 0.5rem;
  font-size: 13px;
  color: #999999;
  float: left;
}

/*分割线1*/
.rule1 {
  position: absolute;
  left: 2.3rem;
  top: 0.1rem;
  border: 0.01rem solid #f7f7f7;
  height: 1rem;
  float: left;
}

/*分割线2*/
.rule2 {
  position: absolute;
  left: 5.3rem;
  top: 0.1rem;
  border: 0.01rem solid #f7f7f7;
  height: 1rem;
  width: 0.01rem;
  float: left;
}

/*装修预算和改造户型*/
.budgetDiv, .remouldDiv {
  font-size: 13px;
  color: #999999;
  margin-left: 1.68rem;
  float: left;
}

/*   装修预算 */
.budgetDiv {
  width: 1.5rem;
  text-align: center;
}

/*    分割线2*/
/* 申请人取值DIV*/
.proposerValueDiv {
  margin-top: 0.3rem;
  font-size: 14px;
  color: #FF5D3B;
  height: 0.5rem;
  float: left;
  /*margin-left: 0.6rem;*/
  width: 2rem;
  /*border: 1px solid red;*/
  text-align: center;
}

/*装修预算和改造户型取值*/
.budgetValueDiv, .remouldValueDiv {
  font-size: 14px;
  color: #FF5D3B;
  margin-top: 0.3rem;
  float: left;
  width: 2rem;
  /*border: 1px solid red;*/
  text-align: center;
}

.budgetValueDiv {
  margin-left: 0.7rem;
}

.remouldValueDiv {
  margin-left: 1rem;
}

/*    分割线3*/
.rule3 {
  border: 0.01rem solid #efefef;
  width: 100%;
  height: 0rem;
  margin-top: 0.1rem;
}

/*    尾部DIV*/
.listFloor {
  margin-left: 0.5rem;
  margin-top: 0.35rem;
  height: 0.73rem;
  line-height: 0.73rem;
}

.listFloor:after {
  content: "";
  display: block;
  clear: both;
}

/*    尾部 创建方式的createMethodsDiv*/
.createMethodsDiv {
  font-size: 13px;
  float: left;
  color: #999999;
}

/*    尾部 创建时间的createTimeDiv*/
.createTimeDiv {
  font-size: 13px;
  float: left;
  margin-left: 0.2rem;
  color: #999999;
  /*margin-top: 0.05rem;*/
}

/*    下部按钮*/
.btn1, .btn2 {
  float: right;
  margin-right: 0.7rem;
  height: 0.82rem;
  width: 2.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
}

/*按钮*/
.btn1 {
  background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
  color: white;
}

.btn2 {
  padding: 0.01rem;
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white, white),
  linear-gradient(to right, #FFC274, #FF5D3B);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  color: #FF5D3B;
}
</style>
